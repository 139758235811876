<app-header></app-header>

<div *ngIf="product" class="product-details">
    <div class="product-image">
      <img [src]="product.product_image_large" alt="{{ product.product_name }}">
    </div>
    <div class="product-info">
      <h2 class="product-name">{{ product.product_name }}</h2>
      <p class="product-price">Price:  {{ product.product_price * conversion_rate | roundUp }} {{ default_curr }}</p>
      <p class="product-color">Color: {{ product.product_color }}</p>
      <p class="product-description">Description: {{ product.description }}</p>
      <div class="product-attributes">
        <p class="attribute" *ngFor="let attribute of product.attributes">
          <span class="attribute-label">{{ attribute.size }}:</span> {{ attribute.price * conversion_rate | roundUp }} {{ default_curr }} (Stock: {{ attribute.stock }})
        </p>
      </div>
    </div>
  </div>
  

<app-bottom  style="position: relative;bottom: -190px;"
  class="mobile_bottom"></app-bottom>