<div class="carousel-search-container">
  <div class="carousel-search-bar">
    <input
      class="carousel-search-input"
      matInput
      type="text"
      [(ngModel)]="searchCityValue"
      (input)="search(searchCityValue)"
      placeholder="{{ 'Home.letsGoTravel' | translate }}"
      [matAutocomplete]="auto"
      (keydown.enter)="handleEnterClickCity()"
    />
    <button class="carousel-search-button" (click)="searchForCityFull()">
      {{ 'Home.search' | translate }}
    </button>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option
        *ngFor="let nobiCity of nobiCities"
        [value]="nobiCity.city"
        (click)="selectResult(nobiCity.city, nobiCity.countryIso, nobiCity.cityId)"
      >
        <span class="city-name">
          {{ nobiCity.city | nameTranslator | async }}
        </span>
        <span class="country-name">
          {{ nobiCity.country | nameTranslator | async }}
        </span>
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
