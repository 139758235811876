<app-header></app-header>
<div class="full_page">

    <div class="content">
        <h1 class="beginning">
            {{"Home.landingh1" | translate}}
            </h1>

            <div>
                {{"Home.landingp1" | translate}}
            </div>
            <div>
                {{"Home.landingp2" | translate}}        
                
            </div>
            <div>
                {{"Home.landingp3" | translate}}
            </div>
            <div>
                {{"Home.landingp4" | translate}}
                <ol>
                    <li>
                        {{"Home.landingl1" | translate}}
                    </li>
                    <li>
                        {{"Home.landingl2" | translate}}
                    </li>
                    <li>
                        {{"Home.landingl3" | translate}}
                    </li>
                </ol>
            </div>
            <div>
                {{"Home.landingp5" | translate}}
            </div>
            <div>
                {{"Home.landingp6" | translate}}
            </div>

    </div>
<!-- info-containers.component.html -->
    <div class="info-containers">
        <ng-container >
        <div class="info-container">
            <h2>92</h2>
            <p>{{"Home.stat1" | translate}}</p>
        </div>
        <div class="info-container">
            <h2>25</h2>
            <p>{{"Home.stat2" | translate}}</p>
        </div>
        <div class="info-container">
            <h2>37</h2>
            <p>{{"Home.stat3" | translate}}</p>
        </div>
        <div class="info-container">
            <h2>87</h2>
            <p>{{"Home.stat4" | translate}}</p>
        </div>
        </ng-container>
    </div>
  

      <a href="https://admin.nobitour.com/ru/page/become-a-vendor" class="ex__btn register-button">{{"Plogin.register" | translate}}</a>

</div>

<app-bottom></app-bottom>