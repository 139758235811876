<!-- New Design -->
<footer class="footer" style="background-image: url('../../assets/img/footer-bg.webp')">
  <div class="wrapper">
    <div class="footer__content">
      <div class="footer__left">
        <a (click)="openFooterLink('/')" class="footer__logo" >
          Nobi Tour
        </a>
        <a (click)="openFooterLink('/contact-us')" class="footer__btn">{{"Bottom.contactus" | translate }}</a>

        <ul class="footer__social">
          <li>
            <a href="https://www.facebook.com/groups/1318905898868523/?ref=share&mibextid=S66gvF" target="_blank">
              <img loading="lazy" src="../../assets/img/icons/fb.webp" alt="" width="40" height="40">
            </a>
          </li>
          <li>
            <a href="https://vk.com/club215414720" target="_blank">
              <img loading="lazy" src="../../assets/img/icons/vk.webp" alt="" width="40" height="40">
            </a>
          </li>
          <li>
            <a href="https://instagram.com/nobi.tour?igshid=MDM4ZDc5MmU=" target="_blank">
              <img loading="lazy" src="../../assets/img/icons/insta.webp" alt="" width="40" height="40">
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__col">
        <p class="footer__title">{{"Home.services" | translate}}</p>
        <ul class="footer__list">
          <li>
            <a (click)="goToHome('estate')">
              <span>
                <img loading="lazy" src="../../assets/img/f-list/icon-1.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/f-list/icon-1-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.hotel' | translate }}
            </a>
          </li>
          <li>
            <a (click)="goToHome('adventure')">
              <span>
                <img loading="lazy" src="../../assets/img/f-list/icon-2.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/f-list/icon-2-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.adventure' | translate }}
            </a>
          </li>
          <li>
            <a (click)="goToWhilteLabel('https://flights.nobitour.com/?locale=ru')">
              <span>
                <img loading="lazy" src="../../assets/img/f-list/icon-3.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/f-list/icon-3-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.flights' | translate }}
            </a>
          </li>
          <!-- <li>
            <a (click)="goToHome('insurance')">
              <span>
                <img loading="lazy" src="../../assets/img/f-list/icon-4.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/f-list/icon-4-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.insurance' | translate }}
            </a>
          </li> -->
          <li>
            <a routerLink="/{{website_language}}/transfer" >
              <span>
                <img loading="lazy" src="../../assets/img/f-list/icon-5.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/f-list/icon-5-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.personalTransfer' | translate }}
            </a>
          </li>
          <li>
            <a (click)="goToWhilteLabel('https://rent-a-car.nobitour.com/#/')">
              <span>
                <img loading="lazy" src="../../assets/img/f-list/icon-6.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/f-list/icon-6-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.rentcar' | translate }}
            </a>
          </li>
          <!-- <li>
            <a (click)="goToHome('avia-bus-russia')">
              <span>
                <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" style="height: 45px;margin-bottom: 5px;"><defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.7px;}</style></defs><title>bus-1</title><path class="a" d="M3,20.213v1.5a1.5,1.5,0,0,0,3,0v-1.5"></path><path class="a" d="M21,20.213v1.5a1.5,1.5,0,0,1-3,0v-1.5"></path><path class="a" d="M22.5,18.713a1.5,1.5,0,0,1-1.5,1.5H3a1.5,1.5,0,0,1,0-3H21A1.5,1.5,0,0,1,22.5,18.713Z"></path><path class="a" d="M5,11.213H19a2,2,0,0,1,2,2v4a0,0,0,0,1,0,0H3a0,0,0,0,1,0,0v-4A2,2,0,0,1,5,11.213Z"></path><path class="a" d="M6,13.838a.375.375,0,0,1,.375.375"></path><path class="a" d="M5.625,14.213A.375.375,0,0,1,6,13.838"></path><path class="a" d="M6,14.588a.375.375,0,0,1-.375-.375"></path><path class="a" d="M6.375,14.213A.375.375,0,0,1,6,14.588"></path><path class="a" d="M18,13.838a.375.375,0,0,1,.375.375"></path><path class="a" d="M17.625,14.213A.375.375,0,0,1,18,13.838"></path><path class="a" d="M18,14.588a.375.375,0,0,1-.375-.375"></path><path class="a" d="M18.375,14.213a.375.375,0,0,1-.375.375"></path><line class="a" x1="10.5" y1="14.213" x2="13.5" y2="14.213"></line><path class="a" d="M19.5,11.276V3.713a3,3,0,0,0-3-3h-9a3,3,0,0,0-3,3v7.563"></path><line class="a" x1="4.5" y1="3.713" x2="19.5" y2="3.713"></line><line class="a" x1="12" y1="3.713" x2="12" y2="11.213"></line><path class="a" d="M4.5,5.213H3a1.5,1.5,0,0,0-1.5,1.5v3"></path><path class="a" d="M19.5,5.213H21a1.5,1.5,0,0,1,1.5,1.5v3"></path></svg>
              </span>
              {{'Home.bus' | translate }}
            </a>
          </li> -->
        </ul>
      </div>
      <div class="footer__col">
        <p class="footer__title">{{"PAddAdventure.information" | translate}}</p>
        <ul class="footer__list">
          <li>
            <a (click)="openFooterLink('contact-us')">
              {{'Bottom.contactus' | translate }}
            </a>
          </li>

          <li>
            <a (click)="openFooterLink('policy')">
              {{'Bottom.policy' | translate}}
            </a>
          </li>

          <li>
            <a (click)="openFooterLink('user_terms_and_conditions')">
              {{'Bottom.user_terms_and_conditions' | translate}}
            </a>
          </li>

          <li>
            <a (click)="openFooterLink('payment_return_policy')">
              {{'PaymentReturnPolicy.name' | translate}}
            </a>
          </li>

          <li>
            <a (click)="openFooterLink('about-us')">
              {{'Bottom.aboutus' | translate}}
            </a>
          </li>

          <li>
            <a (click)="openFooterLink('faq')">
              {{'FrequentlyAskedQuestions.faq' | translate}}
            </a>
          </li>

        </ul>
      </div>
    </div>
    <p class="footer__copyright">Copyright © 2019-{{currentYear}} All Rights Reserved by Nobi Tour</p>
  </div>
</footer>