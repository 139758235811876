<app-header></app-header>
<div class="form-container">
    <form (ngSubmit)="onSubmit()" class="form">
      <div class="form-group">
        <label for="pickupCity">Pick-up City:</label>
        <select id="pickupCity" [(ngModel)]="pickupCity">
          <option value="city1">City 1</option>
          <option value="city2">City 2</option>
          <!-- Add more options as needed -->
        </select>
      </div>
  
      <div class="form-group">
        <label for="dropoffCity">Drop-off City:</label>
        <select id="dropoffCity" [(ngModel)]="dropoffCity">
          <option value="city1">City 1</option>
          <option value="city2">City 2</option>
        </select>
      </div>
  
      <div class="form-group">
        <label for="startDate">Start Date:</label>
        <div class="date-input-container">
          <input matInput readonly [matDatepicker]="startDatePicker" placeholder="Choose a date" id="startDate" [(ngModel)]="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </div>
      </div>

      
    
  
      <div class="form-group">
        <label for="endDate">End Date:</label>
        <div class="date-input-container">
          <input matInput readonly [matDatepicker]="endDatePicker" placeholder="Choose a date" id="endDate" [(ngModel)]="endDate">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </div>
      </div>

      <div class="form-group">
        <label for="filter">Filter:</label>
        <select id="filter" [(ngModel)]="filter">
          <option value="filter1">Filter 1</option>
          <option value="filter2">Filter 2</option>
          <!-- Add more options as needed -->
        </select>
      </div>
  
      <button class="submit_button" type="submit">Search</button>
    </form>
</div>
<br>
<div class="car-list">
  <div *ngFor="let car of cars" class="car-item">
    <img src="{{ car.imageUrl }}" alt="{{ car.name }}">
    <div class="car-details">
      <h3>{{ car.name }}</h3>
      <p>Type: {{ car.type }}</p>
      <p>Price: ${{ car.price }}/day</p>
    </div>
  </div>
</div>
  
<app-bottom></app-bottom>
