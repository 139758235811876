<!-- Hide Lang Bar -->
<div class="hide-lang"></div>

<div fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxLayout="column" fxLayoutAlign="center center" style="margin-top: 100px;">
        <div style="font-size: 22px;">
            {{'RedirectTo.redirectTo' | translate }}
        </div>
        <div style="margin: 80px;">
            {{'RedirectTo.contentText' | translate }}
        </div>
        <div style="margin: 40px">
            <mat-spinner [diameter]="100"></mat-spinner>
        </div>
    </mat-card>
</div>