// const BASE_URL = '/api/';
const BASE_URL = 'https://dev.nobitour.com/api/';
const MT_BASE_URL = 'https://admin.nobitour.ru/api/';
// const MT_BASE_URL = 'https://beta.nobitour.com/api/'; //beta
const NODE_API_SERVICE = 'https://nobitour.onrender.com/';

// const BASE_URL = 'http://beta.nobitour.com/api/';
//const BASE_URL = 'https://nobitour.com/api/';
export const environment = {
  base_url: BASE_URL,
  login: BASE_URL + 'login/user',
  login_admin: BASE_URL + 'login/admin',
  registration: BASE_URL + 'user',
  login_partner: BASE_URL + 'login/partner',
  registration_partner: BASE_URL + 'partner',
  add_estate: BASE_URL + 'estate',
  edit_estate: BASE_URL + 'estate',
  delete_estate: BASE_URL + 'product/',
  save_image: BASE_URL + 'file/image/',
  get_image: BASE_URL + 'file/images/product/',
  add_restaurant: BASE_URL + 'restaurant',
  edit_restaurant: BASE_URL + 'restaurant',
  delete_restaurant: BASE_URL + 'product/',
  add_adventure: BASE_URL + 'adventure',
  edit_adventure: BASE_URL + 'adventure',
  delete_adventure: BASE_URL + 'product/',
  get_all_products: BASE_URL + 'public/product/pagination/',
  get_estate_products: BASE_URL + 'estate',
  delete_estate_products: BASE_URL + 'product/',
  delete_estate_partner: BASE_URL + 'partner/',
  delete_admin_user: BASE_URL + 'user/',
  get_restaurant_products: BASE_URL + 'restaurant',
  get_adventure_products: BASE_URL + 'adventure',
  get_partners: BASE_URL + 'partner',
  get_clients: BASE_URL + 'user',
  approve_reject_products: BASE_URL + 'product/status',
  approve_reject_partner: BASE_URL + 'partner/',
  approve_reject_user: BASE_URL + 'user/change/status',
  get_estate_by_id: BASE_URL + 'public/estate/',
  get_estate_images: BASE_URL + 'public/file/images/product/',
  get_estate_reviews: BASE_URL + 'public/review/',
  get_estate_free_dates: BASE_URL + 'public/free-dates/',
  get_restaurant_by_id: BASE_URL + 'public/restaurant/',
  get_adventure_by_id: BASE_URL + 'public/adventure/',
  get_partner_products_by_type_and_id: BASE_URL + 'product/',
  get_favorite: BASE_URL + 'favorite',
  get_all_favorite: BASE_URL + 'favorite/all/',
  delete_favorite: BASE_URL + 'favorite/',
  edit_client_profile_info: BASE_URL + 'user',
  edit_partner_profile_info: BASE_URL + 'partner',
  reset_client_password: BASE_URL + 'user/password/change',
  reset_partner_password: BASE_URL + 'partner/password/change',
  get_profile_logo: BASE_URL + 'file/',
  get_free_dates_estate: BASE_URL + 'public/free-dates/',
  get_free_dates_restaurant: BASE_URL + 'public/free-dates/',
  get_free_dates_adventure: BASE_URL + 'public/free-dates/',
  add_dates_for_estate: BASE_URL + 'free-dates/',
  add_dates_for_restaurant: BASE_URL + 'free-dates/',
  add_dates_for_adventure: BASE_URL + 'free-dates/',
  booking_details_reservation: BASE_URL + 'public/reservation/',
  review_rating: BASE_URL + 'public/reservation/review/',
  get_notifications: BASE_URL + 'product/count/all',
  forgotten_client_password: BASE_URL + 'user/confirm/reset/',
  forgotten_partner_password: BASE_URL + 'partner/confirm/reset/',
  get_admin_info: BASE_URL + 'admin/info/',
  google_info: BASE_URL + 'public/put-google-info?secret=54dfhagadfgafgDnRLKafg54s654R',
  new_products: BASE_URL + 'admin/whatsnew',
  send_feedback: BASE_URL + 'public/feedback',
  token: BASE_URL + 'public/csrf',
  ip: BASE_URL + 'public/ip',
  nagivation_url: 'https://nobitour.com/',
  choice_dropdown: BASE_URL + 'public/get-choice-drop-down/',
  recommended: BASE_URL + 'public/product/homepage?countries=',
  recommendedByIp : BASE_URL + 'public/product/homepagebyip?language=',
  mt_estate: MT_BASE_URL + 'public/estate',
  hl_estate : MT_BASE_URL  + 'public/h-estate',
  mt_estate_dropdown_search: MT_BASE_URL + 'public/get-hotel-drop-down?search=',
  mt_adventures_dropdown_search: MT_BASE_URL + 'public/get-adventure-drop-down?search=',
  mt_adventure: MT_BASE_URL + 'public/adventure',
  tq_adventure: MT_BASE_URL + 'public/t-adventure',
  tripster_adventure: MT_BASE_URL + 'public/tripster',
  get_one_tiqets_adv: MT_BASE_URL + 'public/adventure',
  get_one_tripster_adv: MT_BASE_URL + 'public/tripster_experience_by_id',
  checkHotelAvailability: MT_BASE_URL + 'public/getCheckAvailability',
  checkTourAvailability: MT_BASE_URL + 'user/tour/availability/loadDates',
  getRecommendedAdventureByCountry: NODE_API_SERVICE + 'recommended_adventures',
  getRecommendedHotelsFromAgoda: NODE_API_SERVICE + 'recommended_hotels',
  sendContactEmail: NODE_API_SERVICE + 'mail/message',
  sendTransferContactMail: NODE_API_SERVICE + 'mail/transfer',
  updateAnalyticsCounter: NODE_API_SERVICE + 'counter/menu?menuItem=',
  getCurrencyRate: NODE_API_SERVICE + 'rate/',
  getChatBotOptions: NODE_API_SERVICE + 'get_chatbot_options?lang=',
  getBotResponses: NODE_API_SERVICE + 'chatbot/?option=',
  getSlideshowResponses: NODE_API_SERVICE + 'slideshow',
  getSavedAgodaHotels: NODE_API_SERVICE + 'agoda_hotels',
  getSavedAdventures: NODE_API_SERVICE + 'tiqets_tripster_adventures',
  tripsterCityId: MT_BASE_URL + 'public/tripster_city_id',
  hotellook_hotels: MT_BASE_URL + 'public/hotels_by_hotellook?'
}

export class ConstantsService { }
