<h3 mat-dialog-title fxLayoutAlign="space-around center">Authorization</h3>

<mat-dialog-content style="height: 100%;">
    <div fxLayoutAlign="space-around center">
        <button mat-button (click)="loginReg = false">{{"Plogin.loginbtn" | translate }}</button>
        <button mat-button (click)="loginReg = true">{{"Plogin.register" | translate }}</button>
    </div>
    <div *ngIf="!loginReg">
        <iframe  width="100%" height="600px" scrolling="no" style="border: none;" [src]="'https://dev.nobitour.com/auth-form-multi.php?language=' + website_language | safe"></iframe>
    </div>
    <!-- <div *ngIf="!loginReg">
        <iframe  width="100%" height="600px" scrolling="no" style="border: none;" [src]="'https://dev.nobitour.com/auth-form-direct.php?language=' + website_language | safe"></iframe>
    </div> -->
    <div *ngIf="loginReg">
          <iframe   width="100%" height="600px" scrolling="no" style="border: none;" [src]="'https://dev.nobitour.com/register-form-multi.php?language=' + website_language | safe"></iframe>
    </div>
    <div class="basic-container" *ngIf="showLoader">
        <div class="spinner-position">
            <mat-spinner [diameter]="100"></mat-spinner>
        </div>
    </div>

</mat-dialog-content>