<!-- New Design -->

<div *ngIf="loadingService.isLoading$ | async" class="loading-overlay">
  <div class="spinner"></div>
</div>

<div class="lang-overlay"></div>
<header class="header">

  <div class="menu">
    <div class="wrapper">
      <div class="menu__main">
        <a *ngIf="!isPartnerMT"
          href="https://admin.nobitour.ru/{{website_language}}/page/become-a-vendor" class="header__btn add_object_burger" style="width:54vh !important"
          matTooltip="{{'Header.vendor_info_text' | translate }}" target="_blank">
          {{ "Home.become_a_vendor" | translate }}
        </a>
        <div *ngIf="showLanguageIcon" class="dropdown-container">
          <div class="dropdown">
            <div *ngIf="isNotRussia" class="selected-item" (click)="toggleCurrDropdown()">
              <!-- {{ selectedCurrency.toUpperCase() }} -->
              {{ selectedCurrency }}

            </div>
            <div class="dropdown-menu" [ngClass]="{'show': isCurrDropdownOpen}">
              <div  class="dropdown-item" *ngFor="let currency of currencies" (click)="onChangeCurrency(currency.value)">
                {{ currency.label }}
              </div>
            </div>
          </div>
        </div>

        <p class="menu__title">{{"Home.services" | translate}}</p>
        <ul class="menu__list">
          <li>
            <a (click)="goToHome('estate')">
              <span>
                <img loading="lazy" src="../../assets/img/menu/icon-1.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/menu/icon-1-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.hotel' | translate }}
            </a>
          </li>
          <li>
            <a (click)="goToHome('adventure')">
              <span>
                <img loading="lazy" src="../../assets/img/menu/icon-2.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/menu/icon-2-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.adventure' | translate }}
            </a>
          </li>
          <li>
            <a href="https://flights.nobitour.com/?locale={{website_language}}" target="_blank">
              <span>
                <img loading="lazy" src="../../assets/img/menu/icon-3.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/menu/icon-3-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.flights' | translate }}
            </a>
          </li>
          <li>
            <a (click)="goToHome('rent-a-car')">
              <span>
                <img loading="lazy" src="../../assets/img/menu/icon-6.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/menu/icon-6-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.rentcar' | translate }}
            </a>
          </li>
         
          <li>
            <a (click)="goToHome('personal-transfer')">
              <span>
                <img loading="lazy" src="../../assets/img/menu/icon-5.webp" alt="" width="24" height="24">
                <img loading="lazy" src="../../assets/img/menu/icon-5-active.webp" alt="" width="24" height="24">
              </span>
              {{'Home.personalTransfer' | translate }}
            </a>
          </li>
          
          <li>
   
          </li>


        </ul>
        <ul class="menu__list">
          <li>
            <a (click)="openHeaderLink('contact-us')">
              {{'Bottom.contactus' | translate }}
            </a>
          </li>
          <li>
            <a (click)="openHeaderLink('policy')">
              {{'Bottom.policy' | translate}}
            </a>
          </li>
          <li>
            <a (click)="openHeaderLink('user_terms_and_conditions')">
              {{'Bottom.user_terms_and_conditions' | translate}}
            </a>
          </li>
          <li>
            <a (click)="openHeaderLink('payment_return_policy')">
              {{'PaymentReturnPolicy.name' | translate}}
            </a>
          </li>
          <li>
            <a (click)="openHeaderLink('about-us')">
              {{'Bottom.about' | translate}}
            </a>
          </li>
          <li>
            <a (click)="openHeaderLink('faq')">
              {{'FrequentlyAskedQuestions.faq' | translate}}
            </a>
          </li>

        </ul>
      </div>
    </div>
  </div>

  <div class="wrapper">
    <div class="header__row">
      <!-- <a  *ngIf="!showShopName" (click)="goBackToIntro()" class="header__logo"> -->
        <a   class="header__logo">

        <img loading="lazy" (click)="goBackToIntro()" src="../../assets/img/logo2.webp" alt="" width="70" height="70">
        
        <span (click)="goBackToIntro()" class="logo_text">Nobi Tour </span>
        <a class="header_menu_item" routerLink="/{{website_language}}/home/adventure" (click)=" goToHomeMainMenu('adventure')">
          {{'Home.adventure' | translate }}
        </a>
        <a class="header_menu_item" routerLink="/{{website_language}}/transfer" target="_blank"
        rel="noopener noreferrer">
          {{'Home.personalTransfer' | translate }}
        </a>
        <a class="header_menu_item" routerLink="/{{website_language}}/home/estate" (click)=" goToHomeMainMenu('estate')">
          {{'Home.hotel' | translate }}
        </a>
        <a class="header_menu_item"  (click)="goToWhilteLabel('https://flights.nobitour.com/?locale=ru')">
          {{'Home.flights' | translate }}
        </a>
        <a class="header_menu_item" (click)="goToWhilteLabel('https://rent-a-car.nobitour.com/#/')">
          {{'Home.rentcar' | translate }}
        </a>
          <a (click)="goToWhilteLabel('https://nobiarts.com')"
          class="header_menu_item" >
          Магазин
          </a>

      </a>
      <a  *ngIf="showShopName" (click)="goToShop()" class="header__logo">
        <img loading="lazy" src="../../assets/img/logo2.webp" alt="" width="70" height="70">
        <span class="logo_text">Nobi Tour Shop</span>
      </a>
      <div class="header__right">
        <a *ngIf="isLoggedIn && isPartnerMT && !is_device_mobile"
          href="https://admin.nobitour.ru/{{website_language}}/user/dashboard" class="header__btn"
          matTooltip="{{'Header.vendor_info_text' | translate }}" target="_blank">
          {{"Home.vendor_dashboard" | translate }}
        </a>
        <a *ngIf="!isPartnerMT && !is_device_mobile"
          href="https://admin.nobitour.ru/{{website_language}}/page/become-a-vendor" class="header__btn add_object"
          matTooltip="{{'Header.vendor_info_text' | translate }}" target="_blank">
          {{ "Home.become_a_vendor" | translate }}
        </a>



        <div class="dropdown header__user">
          <img loading="lazy" src="../../assets/img/icons/user.webp" style="cursor:pointer" alt="" width="32" height="32"
            (click)="toggleDropdown()">
          <a *ngIf="!isLoggedIn" class="truncate-text" (click)="toggleDropdown()">{{userName}}</a>
          <div *ngIf="!isLoggedIn" class="dropdown-menu " [ngClass]="{ 'show': isDropdownOpen }">
            <a style="width:75% !important" class="header__btn" (click)="saveCurrentUrl()"
              href="https://admin.nobitour.ru/{{website_language}}/login">{{"Plogin.loginbtn" |
              translate }}</a>
            <a style="width:75% !important" class="header__btn" (click)="saveCurrentUrl()"
              href="https://admin.nobitour.ru/{{website_language}}/register"
              >{{"Plogin.register" | translate }}</a>
          </div>
        </div>

        <div *ngIf="isLoggedIn" class="dropdown header__user">
          <a *ngIf="isLoggedIn" class="dropdown-toggle truncate-text" (click)="toggleDropdown()">{{userName}}</a>
          <div class="dropdown-menu" [ngClass]="{ 'show': isDropdownOpen }">
            <a class="dropdown-item" href="https://admin.nobitour.ru/{{website_language}}/user/profile"
              target="_blank">{{"Home.my_profile"
              | translate }}</a>
            <a *ngIf="isPartnerMT" class="dropdown-item"
              href="https://admin.nobitour.ru/{{website_language}}/user/dashboard"
              target="_blank">{{"Home.vendor_dashboard" | translate }}</a>
            <a *ngIf="isPartnerMT" class="dropdown-item"
              href="https://admin.nobitour.ru/{{website_language}}/user/hotel/create"
              target="_blank">{{"Home.create_a_hotel" | translate }}</a>
            <a *ngIf="isPartnerMT" class="dropdown-item"
              href="https://admin.nobitour.ru/{{website_language}}/user/hotel" target="_blank">{{"Home.view_my_hotels"
              | translate }}</a>
            <a *ngIf="isPartnerMT" class="dropdown-item"
              href="https://admin.nobitour.ru/{{website_language}}/user/tour/create"
              target="_blank">{{"Home.create_a_tour"
              | translate }}</a>
            <a *ngIf="isPartnerMT" class="dropdown-item"
              href="https://admin.nobitour.ru/{{website_language}}/user/tour" target="_blank">{{"Home.view_my_tours"
              | translate }}</a>
            <a class="dropdown-item" href="https://admin.nobitour.ru/{{website_language}}/user/booking-history"
              target="_blank">{{"Home.booking_history" | translate }}</a>
            <a class="dropdown-item" href="https://admin.nobitour.ru/user/profile/change-password"
              target="_blank">{{"Home.change_password" | translate }}</a>
            <form id="logout-form-topbar" action="https://admin.nobitour.ru/logout" method="POST"
              style="display: none;">
              <input type="hidden" name="_token" value={{token}}>
            </form>
            <a href="#" onclick="event.preventDefault(); document.getElementById('logout-form-topbar').submit();"><i
                class="fa fa-sign-out"></i> {{'Home.logout' | translate }}</a>
          </div>

        </div>

       


        <div class="burger" (click)="onClickMenu()">
          <span class="burger__item"></span>
          <span class="burger__item"></span>
          <span class="burger__item"></span>
        </div>
      </div>
    </div>

  </div>
</header>