<div class="button-container">
    <h2 class="center_text" mat-dialog-title>{{"Home.market" | translate}}</h2>
    <!-- <p>Nobi Arts</p> -->
    <button class="button_adClose" mat-stroked-button color="warn" type="submit" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="market__item">
      <a href="https://nobiarts.com" target="_blank" class="market__logo">
        <img src="../../assets/img/nobi_arts_banner.jpeg" alt="" width="56" height="56">
      </a>
      <a href="https://nobiarts.com" target="_blank" class="market__img">
        <img class="inner_market" src="../../assets/img/markets/img-2.png" alt="" width="200" height="100">
      </a>
    </div>
  </div>