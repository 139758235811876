<app-header></app-header>
<app-carousel></app-carousel>
<div>
    <div *ngIf="showLoader" class="spinner-position faq_spinner">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>
    <div *ngIf="reveal">
      <div class="text_style">
        <h1 class="text__txt">{{"UserAgreementTerms.p1" | translate}}</h1>
        <p class="text__txt">{{"UserAgreementTerms.p2" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p3" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p4" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p5" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p6" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p7" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p8" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p9" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p10" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p11" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p12" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p13" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p14" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p15" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p16" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p17" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p18" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p19" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p20" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p21" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p22" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p23" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p24" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p25" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p26" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p27" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p28" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p29" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p30" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p31" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p32" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p33" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p34" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p35" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p36" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p37" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p38" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p39" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p40" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p41" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p42" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p43" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p44" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p45" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p46" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p47" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p48" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p49" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p50" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p51" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p52" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p53" | translate}}</p>
        <p class="text__txt">{{"UserAgreementTerms.p54" | translate}}</p>
    </div>
  </div>
  <app-bottom *ngIf="!showLoader" style="position: relative;bottom: -190px;"></app-bottom>